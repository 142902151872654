





























































































































































































import {Vue, Component} from "vue-property-decorator";
import QuestListItem from "@/components/QuestListItem.vue";
import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";
import Card from "@/components/Card.vue";
import {Card as ICard, CardStatus, Status} from "@/api/indexeddb/types";
import QuestDetailsResponsive from "@/views/QuestDetailsResponsive.vue";
import CardDetails from "@/components/CardDetails.vue";

@Component({
  name: "QuestListResponsive",
  components:{ QuestListItem, Card, QuestDetailsResponsive, CardDetails }
})

export default class QuestListResponsive extends Vue {
  protected sidebarOpen = false;
  protected notificationOpen = false;
  protected settingsOpen = false;
  protected dropdownOpen = false;
  protected cards = [];
  protected database = new IndexDBClient();
  protected isOpen = false;
  private details = "#Loading Card Details";

  get recommendedCards() {
    this.database.getRecommendedCards(false, 1).then((cards: any) => {
      this.cards = cards;
    })
    return this.cards;
  }

  assignToCard(card: ICard) {
    this.isOpen = false;
    Promise.resolve(this.database.getCardStatus(Status.InProgress)).then((status: CardStatus) =>{
      card.status = status[0];
      this.database.cards = card;
    });
  }

  showCardDetails( id: string ) {
    this.isOpen = true;
    this.details = id;
  }

  get cardDetails(): string {
    const d = this.details;
    return d;
  }

  openCloseMenu() {
    this.isOpen = !this.isOpen;
  }
}
